// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';

// cloudprint
.cloudprint {
  &-home,
  &-sub {
    // body {
    background: #575759;
    // }
    form {
      background: white;
    }

    header.primary {
      margin-top: 19px;
      padding: 2rem 1.75rem 1.75rem;
      height: 125px;

      .contents {
        display: block;
        max-width: 1300px;
        @include medium-up {
          overflow: hidden;
        }

        .logo {
          // height: 35px;
          margin-top: 16px;
          width: 206px;
          height: auto;
          @include large-up {
            width: 240px;
          }

          a {
            width: 206px;
            height: 35px;
            background-image: url('../images/cloudprint-logo.svg');
            background-size: contain;
            background-repeat: no-repeat;
            @include large-up {
              width: 240px;
              height: 44px;
            }
          }
        }
      }

      ul.menu li:last-child {
        margin: 0;

        a {
          display: inline-block;
          position: relative;
          top: -4px;
          margin: 0 0 0 25px;
          padding: 14px 60px 14px 16px;
          height: 40px;
          @include roboto-bold;
          @include fontsize(14 12);
          color: white;
          background-image: url('../images/cloudprint/free-trial-arrow-bg-sm.png'), linear-gradient(to right, rgba($kleinblue, 1) 1%, rgba($kleinblue, 1) 58%, rgba(darken($pictonblue, 5%), 1) 100%);
          background-size: auto, cover;
          background-position: -68px -11px, center center;
          background-repeat: no-repeat, no-repeat;
          border: 0;
          border-radius: 6px;
          @include transition(background-position 0.2s ease-in-out);

          &:after {
            position: absolute;
            top: 14px;
            right: 12px;
            width: 14px;
            height: 14px;
            content: '\F18E';
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            font-size: 16px;
            color: $pear;
          }

          &:visited {
            color: white;
          }

          &:hover {
            background-image: url('../images/cloudprint/free-trial-arrow-bg-sm.png'), linear-gradient(to right, rgba($kleinblue, 1) 1%, rgba($kleinblue, 1) 58%, rgba(darken($pictonblue, 5%), 1) 100%);
            background-position: 5px -11px, center center;
            @include transition(background-position 0.2s ease-in-out);
          }

          &:focus {
            background-image: url('../images/cloudprint/free-trial-arrow-bg-sm.png'), linear-gradient(to right, rgba($kleinblue, 1) 1%, rgba($kleinblue, 1) 100%);
          }
        }
      }
    }

    .free-trial {
      display: block;
      position: relative;
      padding: 20px 25px;
      height: 54px;
      @include roboto-bold;
      @include fontsize(16 12);
      // font-weight: 600;
      color: white;
      text-decoration: none;
      background-color: $prussianblue;
      background-position: center left;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url('../images/cloudprint/free-trial-arrow-bg.png');
      @include transition(all 0.4s ease-in-out);
      @include large-up {
        display: none;
      }

      &:after {
        position: absolute;
        top: 21px;
        right: 25px;
        content: '\F18E';
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        font-size: 22px;
        color: $pear;
      }

      &:visited {
        color: white;
      }

      &:hover,
      &:focus {
        color: $pear;
        @include transition(all 0.4s ease-in-out);
      }
    }

    .user {
      display: block;
      padding-top: 10px;
      position: relative;
      bottom: -10px;
      // position: relative;
      // padding-bottom: .625rem;
      // vertical-align: middle;
      font-size: .85rem;
      text-align: center;
      // font-family: Roboto,sans-serif;
      @include roboto-med;
      line-height: 1.25rem;
      // overflow: hidden;
      @include medium-up {
        display: inline-block;
        position: absolute;
        top: 45px;
        bottom: auto;
        right: 0;
        padding-top: 0;
      }

      .welcome {
        position: relative;
        margin-right: 10px;

        &:after {
          position: absolute;
          right: -8px;
          // margin: 0 5px;
          color: #ebebeb;
          content: '|';
        }
      }

      a {
        display: inline-block;
        position: relative;
        margin-right: 10px;
        // float: right;
        @include roboto-reg;
        border-bottom: solid 2px #ebebeb;
        color: #737373;
        text-decoration: none;

        &:after {
          position: absolute;
          // margin: 0 5px;
          right: -8px;
          color: #ebebeb;
          content: '|';
        }

        &:last-child {
          margin-right: 0;

          &:after {
            display: none;
          }
        }

        &:visited {
          color: #737373;
        }

        &:hover,
        &:focus {
          color: #737373;
        }
      }
    }
  }

  &-home {
    .container {
      padding: 0;
    }

    .content section {
      padding: 40px 25px;
      @include fontsize(18 30);
      @include large-up {
        padding: 70px 20px;
      }

      > div {
        position: relative;
        margin: 0 auto;
        max-width: 1300px;
        z-index: 1;
      }
    }

    [class*='section-blue'] {
      position: relative;
      text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.35);
      background-color: $kleinblue;
      overflow: hidden;

      .bg {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('../images/cloudprint/section-blue.jpg');
        @include large-up {
          animation: coverZoom linear 50s;
        }
      }
    }

    .section-blue-alt {
      text-shadow: none;

      .bg {
        background-image: url('../images/cloudprint/section-blue-alt.jpg');
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .section-white {
      background: white;
    }

    .section-gray {
      background: $alabaster;
    }

    .intro {
      p {
        @include fontsize(18 30);
      }
      @include large-up {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 450px;
        text-align: center;

        p {
          margin: 0 0 10px 0;
          @include fontsize(30 36);

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }

    .goodbye {
      @include large-up {
        > div {
          display: flex;

          .inner {
            width: calc(100% - 190px);
          }
        }

        .inner {
          padding-right: 80px;
        }
      }

      .icon-goodbye {
        display: none;
        @include large-up {
          display: inline-block;
          width: 190px;
          animation: fadein 6s;
        }
      }
    }

    .is {
      p,
      ul {
        @include fontsize(18 28);
      }
    }

    section.outro {
      @include large-up {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 100px;
        width: 100%;
        height: 450px;
      }

      p {
        @include fontsize(18 30);
        @include large-up {
          margin: 0 0 20px 0;
          @include fontsize(30 36);

          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }

  &-sub {
    .subpage-banner {
      height: 110px;
      margin: 0;
      background-image: url('../images/cloudprint/bnr-subpage.jpg');
      background-size: cover;
      @include large-up {
        margin-top: 18px;
      }
    }

    .container {
      position: relative;
      padding-top: 20px;
      @include large-up {
        padding-top: 60px;
      }
    }

    .btn-submit {
      display: inline-block;
      margin: 0 0 1rem 0;
      padding: .75em 2em;
      border: 1px solid transparent;
      border-radius: .3125rem;
      background: $kleinblue;
      color: $alabaster;
      vertical-align: middle;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: .0625rem;
      font-weight: 700;
      font-size: .75rem;
      font-family: Roboto,sans-serif;
      line-height: 1;
      cursor: pointer;
      transition: background-color .25s ease-out, color .25s ease-out;

      &:visited {
        background: $kleinblue;
        color: $alabaster;
      }

      &:focus,
      &:hover {
        background: $resolutionblue;
        color: $alabaster;
        text-decoration: none;
      }
    }
  }

  &-signup {
    .page-title {
      position: absolute;
      top: -74px;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: auto;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0 1px 5px rgba(black, 0.6);
      font-weight: 400;
      font-size: 22px;
      font-family: Roboto, sans-serif;
      @include large-up {
        top: -70px;
      }
    }
  }

  .light-table-header,
  .dark-table-header {
    margin: 0;
    padding: 50px 0;
    @include roboto-bold;
    @include fontsize(25 14);
    color: white;
    text-align: center;
    background: $pictonblue;
    text-shadow: 0 1px 5px rgba(black, 0.6);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .dark-table-header {
    background: $ceruleanblue;
  }

  .pricing-table-light-blue,
  .pricing-table-dark-blue {
    margin-bottom: 40px;

    th,
    td {
      width: 50%;
      text-align: center;
    }

    th {
      position: relative;
      margin: 0;
      padding: 30px 0 20px 0;
      @include roboto-bold;
      @include fontsize(18 18);
      color: white;
      text-shadow: 0 1px 5px rgba(black, 0.6);
      border: 0;

      &:before {
        position: absolute;
        top: -28px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 50px;
        height: 50px;
        content: '';
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

    tr {
      background: white;

      td {
        border-left: 2px solid $mercury;
        border-bottom: 2px solid $mercury;
        border-right: 2px solid $mercury;

        &:last-child {
          @include roboto-bold;
          border-left: 0;
          // border-right: 2px solid $mercury;
        }
      }

      &:last-child td:first-child {
        border-bottom-left-radius: 6px;
      }

      &:last-child td:last-child {
        border-bottom-right-radius: 6px;
      }
    }
  }

  .pricing-table-light-blue th {
    // position: relative;
    background: $pacificblue;
    // border: 0;
    border-top: 2px solid #82dbf7;

    &:first-child {
      // position: relative;
      border-right: 2px solid #82dbf7;

      &:before {
        background-image: url('../images/cloudprint/users-light.png');
      }
    }

    &:last-child:before {
      background-image: url('../images/cloudprint/pricing-light.png');
    }
  }

  .pricing-table-dark-blue th {
    // position: relative;
    background: $kleinblue;
    // border: 0;
    border-top: 2px solid #4e76c0;

    &:first-child {
      border-right: 2px solid #4e76c0;

      &:before {
        background-image: url('../images/cloudprint/users-dark.png');
      }
    }

    &:last-child:before {
      background-image: url('../images/cloudprint/pricing-dark.png');
    }
  }

  .three-block-column {
    flex-wrap: wrap;
    @include medium-up {
      display: flex;
      justify-content: space-between;

      &:after {
        content: '';
        // flex: auto;
        width: calc(100% / 2 - 10px);
      }
    }
    @include large-up {
      &:after {
        width: calc(100% / 3 - 15px);
      }
    }

    .col {
      position: relative;
      text-align: center;
      @include medium-up {
        width: calc(100% / 2 - 10px);
      }
      @include large-up {
        width: calc(100% / 3 - 15px);
      }

      &:last-child {
        margin-bottom: 0;
        @include medium-up {
          margin-bottom: 30px;
        }
      }

      img {
        margin: 10px auto 12px auto;
        width: 72px;
        height: 72px;
      }

      p {
        margin: 0;
        @include fontsize(18 28);
      }

      &:hover {
        img {
          animation: spin 1s 1 ease-in-out;
        }
      }
    }
  }

  .deco {
    position: relative;
    margin-bottom: 30px;
    padding: 30px 30px 54px 30px;
    border: 2px solid $mercury;
    border-radius: 3px;
    background: white;
    @include fontsize(16 24);
  }

  &-sub.cloudprint-sub-sidebar {
    .content-wrapper {
      margin-top: 0;
      @include large-up {
        align-items: flex-start;
      }
    }
    // @include large-up {
    .main {
      @include large-up {
        padding-right: 40px;
        width: calc(100% - 320px);
      }
    }

    .sidebar {
      @include large-up {
        flex: 0 0 320px;
        width: 320px;
        max-width: 100%;
      }

      &-blue {
        margin-bottom: 40px;
        padding: 0 40px 40px 40px;
        background: $ceruleanblue;
        border-radius: 6px;
        color: white;

        &:last-of-type {
          margin-bottom: 0;
        }

        > h2 {
          margin: 0 0 15px -40px;
          padding: 30px 40px;
          width: calc(100% + 80px);
          @include roboto-bold;
          @include fontsize(20 30);
          color: white;
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
          background: $kleinblue;

          span {
            // @include roboto-reg;
            @include fontsize(30 30);
            color: $pear;
          }
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          @include fontsize(16 24);

          li {
            margin: 0 0 15px 0;
            padding: 0 0 15px 0;
            border-bottom: 2px solid rgba(white, 0.15);

            &:last-child {
              border: 0;
            }
          }
        }
      }
    }

    .sidebar-blue {
      &:empty,
      &:blank {
        display: none;
      }

      a {
        color: $pear;

        &:visited {
          color: $pear;
        }

        &:hover,
        &:focus {
          color: $pictonblue;
        }
      }
    }
  }

  .graphic-list {
    list-style: none;
    margin: 0;
    counter-reset: numbers;

    li {
      counter-increment: numbers;
      position: relative;
      margin: 0 0 20px 0;
      padding: 0 0 0 65px;

      &:before {
        content: counter(numbers);
        font-weight: 900;
        display: inline-block;
        position: relative;
        left: -5px;
        color: $kleinblue;
      }

      &:after {
        position: absolute;
        top: -8px;
        left: 0;
        width: 50px;
        height: 50px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        content: '';
      }

      &:first-child:after {
        background-image: url('../images/cloudprint/step-01.png');
      }

      &:nth-child(2):after {
        background-image: url('../images/cloudprint/step-02.png');
      }

      &:last-child {
        margin-bottom: 0;

        &:after {
          background-image: url('../images/cloudprint/step-03.png');
        }
      }
    }

    &.inline {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      @include medium-up {
        flex-wrap: nowrap;
        margin-bottom: 0;
      }

      li {
        display: inline-block;
        margin-right: 20px;
      }
    }

    &.about {
      li:before {
        display: none;
      }

      &:first-child:after {
        background-image: url('../images/cloudprint/about-01.png');
      }

      &:nth-child(2):after {
        background-image: url('../images/cloudprint/about-02.png');
      }

      &:last-child:after {
        background-image: url('../images/cloudprint/about-03.png');
      }
    }
  }

  .decorative-list {
    list-style: none;
    margin: 0;
    counter-reset: numbers;

    > li {
      counter-increment: numbers;
      position: relative;
      margin: 0 0 20px 0;
      padding: 0 0 0 18px;

      &:before {
        content: counter(numbers);
        font-weight: 900;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        color: $kleinblue;
      }

      &:after {
        position: absolute;
        top: -8px;
        left: 0;
        width: 50px;
        height: 50px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        content: '';
      }
    }

    &.inline {
      display: flex;
      flex-wrap: wrap;
      @include medium-up {
        flex-wrap: nowrap;
      }

      li {
        display: inline-block;
        margin-right: 20px;
      }
    }
  }

  .thin {
    @include roboto-reg;
    @include fontsize(25 28);
  }

  .white {
    color: white;
  }

  .green {
    color: $pear;
  }

  .small,
  p.small {
    @include fontsize(13 20 100);
    @include large-up {
      @include fontsize(16 30 100);
    }
  }

  .small-text {
    font-size: 80%;
    line-height: 100%;
  }

  .inline-div {
    display: inline;
  }

  .large {
    @include fontsize(18 30);
    @include large-up {
      @include fontsize(24 36);
    }
  }

  .all-caps {
    text-transform: uppercase;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .btn-green {
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 5px 0;
    width: 100%;
    @include roboto-bold;
    @include fontsize(14 30 100);
    text-transform: uppercase;
    text-align: center;
    text-indent: 0;
    color: $mineshaft;
    background-color: $pear;
    border-radius: 3px;
    overflow: hidden;
    @include medium-up {
      padding-left: 40px;
      padding-right: 40px;
      width: auto;
    }

    &:visited {
      color: $mineshaft;
      background: $pear;
    }

    &:hover {
      background-color: $pictonblue;
      text-decoration: none;
    }

    &:focus {
    }
  }

  .green-animated {
    @extend .btn-green;
    height: 40px;
    text-indent: 0;
    @include transition(background-color .15s ease-in-out, text-indent .15s ease-in-out);

    &:after {
      position: absolute;
      top: 4px;
      left: -100%;
      right: auto;
      margin: 0 auto;
      width: 30px;
      height: 30px;
      content: '\F18E';
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      font-size: 30px;
      color: white;
      @include transition(left .15s ease-in-out, right .15s ease-in-out);
    }

    &:hover {
      text-indent: 100%;
      @include transition(background-color .15s ease-in-out, text-indent .15s ease-in-out);
      @include medium-up {
        text-indent: 150%;
      }

      &:after {
        left: 0;
        right: 0;
        text-indent: 0;
        @include transition(left .15s ease-in-out, right .15s ease-in-out);
        // @include transition(right .2s ease);
      }
    }
  }
}

.content-form.sign-up-form .error-msg {
  top: 12px;
  font-size: 14px;
}

.btn-disabled {
  pointer-events: none;
  cursor: not-allowed;
  background: $mineshaft !important;
}

@keyframes coverZoom {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.24);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.cloudprint-account:not(.cloudprint-signup) {
  header.primary {
    margin-top: 0;
    padding-top: 0;
    height: auto;

    .contents .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: 641px) {
        margin: 0;
        padding-top: 2rem;
      }
    }
  }

  nav.nav-primary {
    display: none;
  }

  .mobile-header {
    display: none;
  }

  .container .content-wrapper {
    // margin-top: 15px;
    margin-top: 0;
  }

  .subpage-banner {
    margin: 0;
  }

  .container {
    padding-top: 15px;
    background-color: #f7f8fa;

    @media (min-width: 1024px) {
      padding-top: 30px;
    }
  }

  .cards {
    list-style: none;
    margin: 0;

    li {
      margin: 0 0 10px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  .account-cards {
    position: relative;

    .col {
      position: relative;
      padding-bottom: 54px;
    }
  }

  .alert.deco {
    padding-bottom: 30px;
    font-weight: 600;
    background-color: rgba(73, 144, 204, 0.2);
  }
}

// .documentation {
//   .container {
//     background: transparent;
//   }

//   .content-wrapper {
//     align-items: flex-start;
//     align-content: flex-start;
//   }

//   .search-container {
//     display: block;
//     float: right;
//     position: relative;
//     padding: 0;
//     width: 260px;

//     input {
//       padding-left: 5px;
//       padding-right: 60px;
//       width: 100%;
//       height: 30px;
//       border: 0;
//     }

//     .search,
//     .cancel {
//       position: absolute;
//       width: 30px;
//       height: 30px;
//     }

//     .cancel {
//       top: 0;
//       right: 0;
//       padding: 5px 0 0 9px;
//       color: white;
//       background: $dovegray;

//       &:hover,
//       &:focus {
//         background: darken($dovegray, 10%);
//       }
//     }

//     .search {
//       top: 0;
//       right: 30px;
//       padding: 5px 0 0 8px;
//       color: white;
//       background: $kleinblue;

//       &:hover,
//       &:focus {
//         background: $resolutionblue;
//       }
//     }
//   }

//   section.main {
//     flex: 0 0 77%;
//     margin: 0 0 100px;
//     padding-right: 30px;
//     padding-left: 30px;
//     max-width: 77%;
//     order: 2;
//     @include medium-up {
//       padding-left: 60px;
//       padding-right: 0;
//     }

//     .box.box-solid.box-default {
//       margin-bottom: 40px;

//       &:last-of-type {
//         margin-bottom: 0;
//       }
//     }
//   }

//   table {
//     p,
//     ul,
//     ol {
//       margin: 0;
//     }
//   }

//   .sidebar {
//     @include large-up {
//       position: -webkit-sticky;
//       position: sticky;
//       top: 0;
//       height: 100vh;
//       overflow-y: scroll;
//     }

//     &.deco {
//       padding: 20px;
//     }

//     // .inner-wrapper-sticky {
//     //   padding: 20px;
//     // }

//     h3 {
//       margin: 0;
//     }

//     .nav-list {
//       list-style: none;
//       margin: 0 0 20px 0;
//     }

//     .divider {
//       height: 1px;
//       background: $mercury;
//       margin: 0 0 5px 0;
//     }

//     a {
//       display: block;

//       &.list-group-item-success,
//       &.list-group-item-info {
//         background: $alabaster;
//       }

//       &:not(.list-group-item) {
//         padding: 0 5px;
//         font-weight: 400;

//         &:hover,
//         &:focus {
//           background: $alabaster;
//           text-decoration: none;
//           border: 0;
//         }
//       }
//     }
//   }
// }

.pymt-mthds {
  width: 100%;
  max-width: 100%;
  height: 500px;
}